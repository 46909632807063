<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />

                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" :alt="$t(`pages.${pagename}.pagetitle`)" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Forgot password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t(`pages.${pagename}.msg1`) }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(`pages.${pagename}.msg2`) }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form
                            class="auth-forgot-password-form mt-2"
                            @submit.prevent="validationForm"
                        >
                            <b-form-group label="Email" label-for="forgot-password-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="forgot-password-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false : null"
                                        name="forgot-password-email"
                                        placeholder="abc@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-button type="submit" variant="primary" block>
                                {{ $t(`pages.${pagename}.msg3`) }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{ name: 'Login' }">
                            <feather-icon icon="ChevronLeftIcon" />
                            {{ $t(`pages.${pagename}.msg4`) }}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { Tientech_getPageRandomNumber } from '@/libs/tientech/api'
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author, company,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'forgotpassword',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      company,
      author,
    }
  },
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      random_number: 0,
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    this.random_number = Tientech_getPageRandomNumber()
  },
  methods: {
    initialize() {
      try {
        // initialize
      } catch (error) {
        console.log(error)
      }
    },
    validationForm() {
      const vuethis = this
      this.$refs.simpleRules.validate().then(success => {
        if (!success) {
          vuethis.$swal({
            icon: 'error',
            title: this.$t('error_common.input_error'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          return
        }

        const obj = {
          email: this.userEmail,
          appName: this.appName,
          companyName: this.companyName,
          companyUrl: this.companyUrl,
          company: this.company,
        }
        console.log(obj)
        this.$socket.emit('accountcloud_forgot', {
          data: obj,
          callback_params: `${this.pagename}_${this.random_number}`,
        }, params => {
          try {
            let errormsg = ''
            if (params.type === 'error') {
              if (params.msg === 'account_error_emailisnotexist') {
                errormsg = vuethis.$t('error_common.account_error_emailisnotexist')
              } else {
                errormsg = params.msg
              }
              vuethis.$swal({
                icon: 'error',
                title: errormsg,
                showConfirmButton: true,
                timer: 2000,
                buttonsStyling: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            } else {
              // success
              vuethis.$swal({
                icon: 'success',
                title: vuethis.$t(`pages.${vuethis.pagename}.msg6`),
                showConfirmButton: true,
                timer: 3000,
                buttonsStyling: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(result => {
                this.$router.push({ name: 'Login' }).catch(error => {
                  // console.warn(error.message)
                })
              })
            }
          } catch (error) {
            console.log(error)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
